<template>
	<div>
		<ContentHeader3 title="Administrasi" url="/administrasi" title2="Data Administrasi" url2="/data-administrasi" subTitle="Tambah Data Administrasi" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
		            <div class="col">
		            	<div class="card">
		            		<div class="card-header row">
		            			<h3 class="card-title">Input Data Administrasi</h3>
		            		</div>
		            		<div class="card-body row">
		            			<div class="col-2">

								</div>
		            			<div class="col-8">
									<form @submit.prevent="prosesDataAdministrasi">
										<div class="px-lg-2 ">
										<div class="form-group">
											<label for="kode_administrasi">Kode Administrasi</label>
											<input type="text" class="form-control" id="kode_administrasi" name="kode_administrasi"  disabled="" v-model="administrasi.kode_administrasi">
										</div>
										<div class="form-group">
											<label for="poli">Poli</label>
											<select name="poli" id="poli" class="custom-select form-control" v-model="administrasi.poli_id">
												<option v-for="(row, index) in poli" :key="index" :value="row.id">{{row.nama}}</option>
											</select>
										</div>
										<div class="form-group">
											<label for="nama">Nama</label>
											<input type="text" class="form-control" id="nama" name="nama" autocomplete="off" placeholder="Nama Administrasi" v-model="administrasi.nama" required="">
										</div>
										<div class="form-group">
											<label for="fee_dokter">Tarif</label>
											<div class="input-group mb-3">
											<div class="input-group-prepend">
												<div type="button" class="btn btn-secondary">Rp.</div>
											</div>
											<!-- /btn-group -->
											<input type="number" class="form-control" id="harga" name="harga" placeholder="Fee Dokter" v-model="administrasi.harga" required="">
											</div>
										</div>
										<div class="form-group">
											<label for="fee_dokter">Fee Dokter</label>
											<div class="input-group mb-3">
											<div class="input-group-prepend">
												<div type="button" class="btn btn-secondary">{{ fee_dokter.label }}</div>
											</div>
											<!-- /btn-group -->
											<input type="number" class="form-control" id="fee_dokter" name="fee_dokter" @keyup="fee_dokter.changeLabel" placeholder="Fee Dokter" v-model="administrasi.fee_dokter">
											</div>
										</div>
										<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Simpan</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											<span class="sr-only">Loading...</span>
											</div>
										</button>
										</div>
										<!-- /.card-body -->					               
									</form>
								</div>
		            			<div class="col-2">

								</div>
		            		</div>
		            	</div>
		            </div>
		        </div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader3 from '@/components/ContentHeader3'

import { reactive, computed, onMounted, ref } from 'vue'
import store from '@/store'
import axios from 'axios'
import tambah_nol from '@/tambah_nol.js'
import Swal from 'sweetalert2'
import router from '@/router'

const user = computed(() => store.getters['auth/user'])
const poli = computed(() => store.getters['poli/getPoli'])
const loading = ref(false)

// Fetching Data
store.dispatch('poli/fetchPoli')

const { generateKodeAdministrasi, kode_baru } = useGetKodeAdministrasi(user)

const { administrasi, prosesDataAdministrasi } = useProsesTambahAdministrasi(user, kode_baru)

const fee_dokter = reactive({
	label: '...',
	changeLabel: function(){
		if (administrasi.fee_dokter <= 100 && administrasi.fee_dokter > 0) {
			this.label = 'Persen (%)' 
		} else if(administrasi.fee_dokter > 100) {
			this.label = 'Rp.'
		} else {
			this.label = '...'
		}
	}	
})

onMounted(() => {
	generateKodeAdministrasi()
})


function useGetKodeAdministrasi (user) {
	// Generate Kode Administrasi
	const kode_administrasi_baru = reactive({})
    const generateKodeAdministrasi = () => {
    	axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)
    	.then((response) => {
    		kode_administrasi_baru.value = `${response.data.kode_cabang}-A`
    	}) 
    	.catch((error) => {
    		console.log(error)
    	})

    	axios.get(`api/administrasi/getLastId/${user.value.cabang_id}`)
    	.then((response) => {
    		console.log(response)
    		if(response.data.id == null){
    			kode_administrasi_baru.value = `${kode_administrasi_baru.value}-${tambah_nol(1)}`
    		} else {
    			kode_administrasi_baru.value = `${kode_administrasi_baru.value}-${tambah_nol(response.data.id + 1)}`
    		}
    	})
    	.catch((error) => {
    		console.log(error)
    	})
    }
    const kode_baru = computed(() => kode_administrasi_baru.value)
    // End

    return {
    	generateKodeAdministrasi,
    	kode_baru
    }
}

function useProsesTambahAdministrasi (user, kode_baru) {
	const administrasi = reactive({
		cabang_id: user.value.cabang_id,
		poli_id: null,
		kode_administrasi: kode_baru,
		jenis_administrasi: 'administrasi',
		nama: '',
		harga: 0,
		fee_dokter: 0
	})

	// Proses Store Data
    const prosesDataAdministrasi = () => {
		loading.value = true
		axios.post('api/administrasi/create', administrasi)
		.then((response) => {
			Swal.fire({
			  title: 'Berhasil!',
			  text: 'Berhasil menambahkan data',
			  icon: 'success',
			  showConfirmButton: false,
			  timer: 1500
			})	
			router.replace('/data-administrasi')
			console.log(response)
		})
		.catch((error) => {
			Swal.fire({
			  title: 'Gagal!',
			  text: 'Gagal menambahkan data',
			  icon: 'error',
			  showConfirmButton: false,
			  timer: 1500
			})	
			console.log(error)
		})
		.finally(() => {
			loading.value = false
		})
    }
    // End

    return {
    	administrasi,
    	prosesDataAdministrasi
    }
}
</script>